@use "@amzn/awsui-design-tokens/polaris" as awsui;

.deviceTable {
  // selecting all "tree line" svgs and avoiding other svgs
  tr td div {
    /* stylelint-disable-next-line declaration-no-important -- extend and move tree line */
    svg[class*="related-table-tree-line"]:only-of-type {
      height: auto !important;
      margin: 22px 0 0 !important;
    }

    /* stylelint-disable-next-line declaration-no-important -- move tree line for child items */
    svg[class*="related-table-tree-line"]:not(:only-of-type):first-child {
      height: calc(100% + 170px) !important;
      margin: -35% 0 0 !important;
    }

    button[class*="awsui_button_"] {
      margin-bottom: 2px;
    }
  }

  & line {
    /* stylelint-disable-next-line declaration-no-important -- darken the tree lines used by related table */
    stroke: awsui.$color-border-button-normal-disabled !important;
  }
}